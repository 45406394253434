import { useEffect, useState } from "react";
import { Col, Row, Steps, Typography } from "antd";
import useTranslation from "next-translate/useTranslation";
import PropTypes from "prop-types";

import useWindowSize from "../../utils/useWindowSize";

const { Title, Paragraph } = Typography;
const { Step } = Steps;

function AuctioningProcess({ duration }) {
  const { t } = useTranslation("home");

  // State to determine the currentStep in the process.
  const [step, setStep] = useState(0);
  const { width } = useWindowSize();
  const md = width < 992;

  // Set a timeout that increases the currentStep if the currentStep is not yet reached. It is
  // cleared when the step or duration is changed.
  useEffect(() => {
    let ticker;

    if (step < 4) {
      ticker = setTimeout(() => {
        setStep(step + 1);
      }, duration / 4);
    }

    return () => {
      clearTimeout(ticker);
    };
  }, [step, duration]);

  return (
    <Row className="auctioning-process">
      <Col span={24}>
        <Title level={2} style={{ marginBottom: 20 }}>
          {t("auctioning_title")}
        </Title>
        <Paragraph style={{ marginBottom: 40 }}>
          {t("auctioning_subtitle")}
        </Paragraph>
        {width && (
          <Steps
            direction={md ? "vertical" : "horizontal"}
            current={step}
            onChange={(current) => setStep(current)}
          >
            <Step
              title={t("common:dataroom")}
              description={t("auctioning_dataroom")}
            />
            <Step
              title={t("common:individual_asset_bidding")}
              description={t("auctioning_individual")}
            />
            <Step
              title={t("common:combined_asset_bidding")}
              description={t("auctioning_combined")}
            />
            <Step
              title={t("common:closed")}
              description={t("auctioning_closed")}
            />
          </Steps>
        )}
      </Col>
    </Row>
  );
}

AuctioningProcess.propTypes = {
  duration: PropTypes.number.isRequired,
};

export default AuctioningProcess;
