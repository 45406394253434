// Format a string containing numbers with certain separator and groupSize. For example string
// is 100000000, separator is . and groupSize is 3 --> result is 100.000.000.
function formatAmountString(string, separator, groupSize) {
  // If no string or string is smaller or equal to groupSize, return string.
  if (!string) return string;
  if (groupSize >= string.length) return string;

  // Start at the index that is one place after the last character. This is because in
  // slice(start, end), end is not included.
  let currentIndex = string.length;
  let formattedString = string;

  // Step through the string with groupSize steps and insert the separator each time.
  while (currentIndex > groupSize) {
    currentIndex -= groupSize;
    formattedString =
      formattedString.slice(0, currentIndex) +
      separator +
      formattedString.slice(currentIndex);
  }

  return formattedString;
}

export default formatAmountString;
