import Head from "next/head";
import PropTypes from "prop-types";

// Head tag is a Next component that resembles the HTML <head> tag. It has a title, favicon and
// other metadata that is important for SEO.
function HeadTag({ title, description, url }) {
  return (
    <Head>
      <title>{title || "Bexchange"}</title>
      <meta
        name="description"
        content={
          description ||
          "Europe's leading private insolvency auctioning platform."
        }
      />
      <meta charSet="UTF-8" />
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
      />
      <meta name="robots" content="'index, follow'" />
      <meta name="application-name" content={title || "Bexchange"} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content={title || "Bexchange"} />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="msapplication-config" content="/browserconfig.xml" />
      <meta name="msapplication-TileColor" content="#00225D" />
      <meta name="msapplication-tap-highlight" content="no" />
      <meta name="theme-color" content="#00225D" />

      <meta property="og:type" content="website" />
      <meta
        name="og:title"
        property="og:title"
        content={title || "Bexchange"}
      />
      <meta
        name="og:description"
        property="og:description"
        content={
          description ||
          "Europe's leading private insolvency auctioning platform."
        }
      />
      <meta property="og:site_name" content="Bexchange" />
      <meta property="og:url" content={url || "https://bexchange.eu"} />
      <meta
        property="og:image"
        content="https://bexchange.eu/static/icons/apple-touch-icon.png"
      />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title || "Bexchange"} />
      <meta
        name="twitter:description"
        content={
          description ||
          "Europe's leading private insolvency auctioning platform."
        }
      />
      <meta name="twitter:site" content={url || "https://bexchange.eu"} />
      <meta name="twitter:creator" content="@Bexchange" />
      <meta
        name="twitter:image"
        content="https://bexchange.eu/static/icons/android-chrome-192x192.png"
      />

      <link
        rel="icon"
        sizes="16x16"
        type="image/png"
        href="/favicon-16x16.png"
      />
      <link
        rel="icon"
        sizes="16x16"
        type="image/png"
        href="/favicon-32x32.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
      <link rel="canonical" href={url || "https://bexchange.eu"} />
      <link rel="manifest" href="/manifest.json" />
      <link
        rel="manifest-icon"
        href="/android-chrome-512x512.png"
        color="#00225D"
      />
      {/* <script type="text/javascript" src="https://js-cdn.dynatrace.com/jstag/16827775e4e/bf01650mmr/f5bafbdb7b21f2cf_complete.js" crossorigin="anonymous"></script> */}
    </Head>
  );
}

HeadTag.defaultProps = {
  title: "Bexchange",
  description: "Europe's leading private insolvency auctioning platform.",
  url: "https://bexchange.eu",
};

HeadTag.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
};

export default HeadTag;
