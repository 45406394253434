import { Button, Col, Row, Space, Typography } from "antd";
import PropTypes from "prop-types";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { useDispatch, useSelector } from "react-redux";

import FormattedParagraph from "../custom-typography/FormattedParagraph";

import { viewerStatus } from "../../store/userInfo";
import formatAmountString from "../../utils/formatAmountString";

const { Title, Text } = Typography;

// Shows a statistic which starts at 0 and increases linearly to to its actually value (number) in
// specified time (duration in s).
const StatisticCounter = ({ label, duration, number }) => {
  const [value, setValue] = useState("0");

  // The minimum delay for setInterval is 10 ms. For simplicity this is chosen to be the interval
  // always.
  useEffect(() => {
    if (number === 0) return null;

    // Calculate the total steps to be done and the value that must be added each step to end up
    // with the actual number.
    const totalSteps = (duration * 1000) / 10;
    const addValue = number / totalSteps;

    let stepsDone = 0;
    let currentValue = 0;

    // Each step, increase the current value and stepsDone. If all steps are done, set the value to
    // the number and clear the interval.
    const ticker = setInterval(() => {
      stepsDone += 1;
      currentValue += addValue;
      setValue(formatAmountString(String(Math.round(currentValue)), ".", 3));
      if (stepsDone === totalSteps) {
        setValue(formatAmountString(String(number), ".", 3));
        clearInterval(ticker);
      }
    }, 10);

    return () => {
      clearInterval(ticker);
    };
  }, [duration, number]);

  return (
    <Space direction="vertical">
      <Text style={{ color: "white", fontSize: 16 }}>{label}</Text>
      <Title style={{ color: "white" }} level={2}>
        {value}
      </Title>
    </Space>
  );
};

StatisticCounter.propTypes = {
  label: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  number: PropTypes.number.isRequired,
};

function HeroContainer({
  title,
  description,
  buttons,
  statistics,
  auctions,
  // users,
  bids,
  lots,
}) {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.userInfo);

  const Statistics = () => (
    <Row
      gutter={[
        {
          xs: 8,
          sm: 8,
          md: 16,
          lg: 24,
        },
        {
          xs: 8,
          sm: 8,
          md: 16,
          lg: 24,
        },
      ]}
    >
      <Col span={8}>
        <StatisticCounter
          label={t("bids")}
          number={Number(bids)}
          duration={2}
        />
      </Col>
      <Col span={8}>
        <StatisticCounter
          label={t("auctions")}
          number={Number(auctions)}
          duration={2}
        />
      </Col>
      {/* <Col span={12}>
        <StatisticCounter
          label={t("registered_users")}
          number={Number(users)}
          duration={2}
        />
      </Col> */}
      <Col span={8}>
        <StatisticCounter
          label={t("lots")}
          number={Number(lots)}
          duration={2}
        />
      </Col>
    </Row>
  );

  return (
    <div className="hero-container" style={{ position: "relative" }}>
      <Image
        className="hero-background"
        src="/images/hero-background2.png"
        alt="hero background"
        style={{ transform: "scaleX(-1)" }}
        layout="fill"
        objectFit="cover"
        objectPosition="center"
        priority
        quality={100}
      />

      <Row
        gutter={[
          { xs: 0, sm: 0 },
          { xs: 24, sm: 24 },
        ]}
        className="hero-content"
      >
        <Col lg={10} md={11} xs={24}>
          {title && (
            <Title level={1} style={{ color: "white" }}>
              {title}
            </Title>
          )}
          {description && (
            <FormattedParagraph style={{ color: "white" }}>
              {description}
            </FormattedParagraph>
          )}
          {buttons && (
            <>
              {!reduxUser?.currentUser && (
                <Button
                  key="sign-up"
                  type="primary"
                  onClick={() => dispatch(viewerStatus(true))}
                >
                  {t("sign_up")}
                </Button>
              )}
              <Button style={{ marginLeft: !reduxUser?.currentUser ? 12 : 0 }}>
                <Link href="/about">{t("learn_more")}</Link>
              </Button>
            </>
          )}
        </Col>
        <Col lg={4} md={2} xs={0} />
        <Col lg={10} md={11} xs={24}>
          {statistics && <Statistics />}
        </Col>
      </Row>
    </div>
  );
}

HeroContainer.defaultProps = {
  description: null,
  buttons: null,
  statistics: false,
  auctions: 0,
  bids: 0,
  users: 0,
  lots: 0,
};

HeroContainer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  buttons: PropTypes.bool,
  statistics: PropTypes.bool,
  auctions: PropTypes.number,
  bids: PropTypes.number,
  users: PropTypes.number,
  lots: PropTypes.number,
};

export default HeroContainer;
