import { Typography } from "antd";
import PropTypes from "prop-types";

const { Paragraph } = Typography;

// Regular ant Paragraph component but it renders newlines (\n) properly.
function FormattedParagraph({ children, style }) {
  return (
    <Paragraph style={{ ...style, whiteSpace: "pre-line" }}>
      {children}
    </Paragraph>
  );
}

FormattedParagraph.defaultProps = {
  style: null,
};

FormattedParagraph.propTypes = {
  children: PropTypes.string.isRequired,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

export default FormattedParagraph;
